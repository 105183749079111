<template>
  <div style="position:relative">
    <div class="is-flex truck-name-div">
      <span v-if="data.data && !collapsed" class="truck-side p-2" :class="{
        'planning-board-opaque': job.status_id == 0,
        'loading-board-opaque': job.status_id == 1,
        'departured-board-opaque': job.status_id == 2,
      }">

        <!-- <div v-if="job.connected_project_id" class="is-flex mb-1 blink_me has-text-primary">
					<img v-if="job.connected_project_id" width="16px" height="16px" src="/icons/transit_connection.png" >
					<span class="mt-1 ml-2 has-text-weight-bold">
						TRANSIT JOB
					</span>
				</div> -->

        <i v-if="weights.Weight > data.data.NetWeight" class="mr-1 fa fa-exclamation-triangle has-text-danger"></i>
        <b class="kban-truck-name name-truck">{{ truck_name[0]}}</b>
        <b class="ml-1">{{ truck_name[1]}}</b>
      </span>
      <span v-if="!data.data && !collapsed" class="px-2 truck-side">
        <i class="fa fa-exclamation-triangle has-text-danger ml-1"></i>
        NO TRUCK
      </span>


      <div class="" v-if="show">
        <i class="fas fa-truck fa-lg" v-if="data.data"
          style="font-size: 26px; position: relative; top: 5px;color:#5c7e6b;"></i>
        <i class="fas fa-truck fa-lg" v-else style="font-size: 26px; position: relative; top: 5px;color:#6b5a5c;"></i>
      </div>

    </div>
    <div class="circle" v-if="data.data && show"></div>
    <div class="circle ml-4" v-if="data.data && show"></div>
    <div class="circle ml-6" v-if="data.data && show"></div>
    <div class="mt-1" v-if="data.data && collapsed">
      <i class="ml-2">{{ data.data.name }}</i><br>
      <!-- <i>{{data.fields[0].value}}</i><br> -->
      <span class="ml-2"
        :class="[weights.Weight > data.data.NetWeight ? 'has-text-white p-1 has-background-danger' : '']">
        <b>{{ weights.Weight | number }} / {{ data.data.NetWeight | number }}</b> Kg
      </span>

      <div v-if="weights.Weight > data.data.NetWeight" class="ml-2 mt-1">
        <span class="has-text-danger-dark">
          OVERLOADED
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "weights", "collapsed", "show", "job"],
  computed: {
    truck_name() {
      let name = this.data.fields[0].value.substring(0, this.data.fields[0].value.length - 4)
      let rest = this.data.fields[0].value.substring(this.data.fields[0].value.length - 4)
      return [name, rest]
    }
  }
}
</script>

<style scoped>
.truck-side {
  /* border-color: #343b38; */
  /* background: #5c7e6b; */
  /* color: #fff; */
  line-height: 1.2em;
      margin: auto;
      min-width: 100%;
    text-align: center;

}

.circle {
  border: 3px solid #257953;
  background-color: #fafafa;
  height: 9px;
  border-radius: 50%;
  width: 9px;
  position: absolute;
  top: 18px;
  left: 25px;
}
.truck-name-div {
      min-width: 120px;

}
.name-truck {
  font-family: 'Changa', sans-serif;
  font-weight: bold;
}
</style>

